/* eslint-disable @typescript-eslint/no-explicit-any */
(window as any).__Zone_disable_defineProperty = true;
(window as any).__Zone_disable_registerElement = true;
// (window as any).__Zone_disable_EventTargetLegacy = true; // kept in sync with `__Zone_disable_EventTarget`
// (window as any).__Zone_disable_timers = true;
(window as any).__Zone_disable_requestAnimationFrame = true;
(window as any).__Zone_disable_queueMicrotask = true;
(window as any).__Zone_disable_blocking = true;
// (window as any).__Zone_disable_EventTarget = true; // breaks the modals, dropdowns and tooltips :(
(window as any).__Zone_disable_FileReader = true;
(window as any).__Zone_disable_MutationObserver = true;
(window as any).__Zone_disable_IntersectionObserver = true;
// (window as any).__Zone_disable_on_property = true; // breaks datatable scroll
(window as any).__Zone_disable_customElements = true;
(window as any).__Zone_disable_XHR = true;
(window as any).__Zone_disable_geolocation = true;
(window as any).__Zone_disable_canvas = true;
// (window as any).__Zone_disable_ZoneAwarePromise = true; // breaks the app completely, cannot bootstrap (most likely due to core-js)
(window as any).__Zone_ignore_on_properties = [
  {
    target: XMLHttpRequest.prototype,
    ignoreProperties: ['readystatechange'], // apart from the ones already disabled by __Zone_disable_XHR
  },
  {
    target: XMLHttpRequestEventTarget.prototype,
    ignoreProperties: ['abort', 'error', 'load', 'loadend', 'loadstart', 'progress', 'timeout'],
  },
  {
    target: WebSocket.prototype,
    ignoreProperties: ['message', 'close', 'open', 'error'],
  },
  {
    target: Worker.prototype,
    ignoreProperties: ['message', 'error'],
  },
];
//(window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove']; // breaks datatable scroll
(window as any).__zone_symbol__PASSIVE_EVENTS = ['keydown'];
/* eslint-enable @typescript-eslint/no-explicit-any */
